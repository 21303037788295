import styled from 'styled-components'
import { BREAKPOINT_S } from '../../constants'

export const Title = styled.h1`
  font-size: 20px;
  line-height: 30px;
  margin: 0;
  margin-bottom: 8px;
`

export const Description = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin: 0;

  @media (min-width: ${BREAKPOINT_S}) {
    word-spacing: 1px;
  }
`
