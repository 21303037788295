import { createGlobalStyle } from "styled-components"

export const GlobalStyles = createGlobalStyle`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  a,
  button,
  div {
    font-display: optional;
    font-family: "Open Sans", "Helvetica Neue", sans-serif;
    color: #273653;
  }

  body {
    margin: 0;
    font-family: "Open Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    z-index: 1;
  }
`
